



























































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { Widget, WidgetField } from "@/models/Widget";
import { Template } from "@/models/Template";
import { Region } from "@/models/Region";
import { IndicatorTable } from "@/models/Indicator";
import {
	WIDGET_REQUEST,
	WIDGET_UPDATE,
	WIDGET_CREATE,
	WIDGET_DELETE,
	WIDGET_UPDATED,
	WIDGET_LOADED,
} from "@/store/actions/widget";
import { TEMPLATE_REQUEST, TEMPLATE_LOADED } from "@/store/actions/template";
import {
	INDICATOR_TABLE_REQUEST,
	INDICATOR_TABLE_LOADED,
} from "@/store/actions/indicatorTable";
import { REGION_REQUEST, REGION_LOADED } from "@/store/actions/main";
import ChartPreview from "@/components/ChartPreview.vue";
import { EChartTypes } from "@/misc/Enums";
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";

@Component({
	components: {
		ChartPreview,
		vueCustomScrollbar,
	},
})
export default class WidgetsForm extends Vue {
	private form: any = {};
	private regions: any = [];
	private templates: Template[] = [];
	private indicatorsTables: IndicatorTable[] = [];
	private loading: boolean = false;
	private test = 0;
	private widgetId: number = 0;
	private fields: any = [];
	private fieldsHeaders = [
		{
			text: "Ключ",
			value: "key_name",
			align: "start",
		},
		{
			text: "Тип ключа",
			value: "key_type",
			align: "start",
		},
		{
			text: "Цвет",
			value: "key_color",
			align: "start",
			sortable: false,
		},
		{
			text: "Название",
			value: "field_title",
			align: "center",
		},
		{
			text: "Значение",
			value: "field_value",
			align: "center",
		},
	];
	private options = {
		page: 1,
		itemsPerPage: -1,
	};
	//preview
	private chartType: EChartTypes = EChartTypes.Diagram;
	private previewLabels = ["Данные", "Другие Данные"];
	private previewColors = [] as any;
	private previewData = [10, 15];
	private previewName = "";
	private totalValue = 0;
	private totalTitle = "Итого";
	//Snackbar
	private messageText = "Не удалось выполнить";
	private messageColor = "red";
	private message = false;
	private loadingBtn = false; //Заглушка кнопок
	//Map
	private ctx: any;
	private editing: boolean = false;
	private region: any = false;
	private currentMap: number = 0;
	private currentMarker: number = 0;
	private currentMarkerCoords: any = { x: 0, y: 0 };
	//Scrollbar
	private scrollbarSettings = {
		suppressScrollY: false,
		suppressScrollX: false,
		wheelPropagation: false,
	};
	$refs!: {
		chart: any;
		canvas: any;
	};
	//Initialise methods
	private initIfReady() {
		//return true if started to init self.
		let needReload = [] as boolean[];

		needReload.push(
			this.needReload(
				this.$store.getters.REGION_TABLE,
				this.$store.getters.REGION_LOADED
			)
		);
		needReload.push(
			this.needReload(
				this.$store.getters.TEMPLATE_TABLE,
				this.$store.getters.TEMPLATE_LOADED
			)
		);
		needReload.push(
			this.needReload(
				this.$store.getters.INDICATOR_TABLE_TABLE,
				this.$store.getters.INDICATOR_TABLE_LOADED
			)
		);
		for (let i = 0; i < needReload.length; i++) {
			if (needReload[i] == true) {
				return false;
			}
		}
		console.log("TRUE INIT SELF");
		this.initSelf();
		return true;
	}
	private needReload(table, updated) {
		/*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
		if (table == null || !updated) return true;
		return false;
	}
	private tryToInitSelf() {
		this.loading = true;
		//const data = this.getParams();
		if (this.$store.getters.WIDGET_TABLE == null) {
		}
		if (this.initIfReady()) return;

		if (
			this.needReload(
				this.$store.getters.REGION_TABLE,
				this.$store.getters.REGION_LOADED
			)
		) {
			this.$store.dispatch(REGION_REQUEST).then(() => {
				this.initIfReady();
			});
		}
		if (
			this.needReload(
				this.$store.getters.INDICATOR_TABLE_TABLE,
				this.$store.getters.INDICATOR_TABLE_LOADED
			)
		) {
			this.$store.dispatch(INDICATOR_TABLE_REQUEST).then(() => {
				this.initIfReady();
			});
		}
		if (
			this.needReload(
				this.$store.getters.TEMPLATE_TABLE,
				this.$store.getters.TEMPLATE_LOADED
			)
		) {
			this.$store.dispatch(TEMPLATE_REQUEST).then(() => {
				this.initIfReady();
			});
		}
	}
	private initSelf() {
		console.log("INIT SELF upd");
		this.templates = this.$store.getters.TEMPLATE_TABLE.data;
		this.indicatorsTables = this.$store.getters.INDICATOR_TABLE_TABLE.data;
		this.regions = this.$store.getters.REGION_TABLE.data;
		this.initStartOptions();
		//this.$router.currentRoute.meta.breadcrumb[2].text = this.form.indicator_table_name;
		//this.initStartOptions();
		this.loading = false;
	}
	//Methods
	private getType(n) {
		if (n == 1) return "int";
		if (n == 2) return "double";
		if (n == 3) return "string";
		return "undefined";
	}
	private editItem() {
		this.loadingBtn = true;
		var arr = this.getCleanFields();
		this.form.widget_fields = arr;
		this.form.id = +this.$route.params.id;
		this.$store
			.dispatch(WIDGET_CREATE, this.form)
			.then(() => {
				this.messageText = "Виджет " + this.form.widget_name + " изменен.";
				this.messageColor = "green";
				this.tryToInitSelf();
			})
			.catch(() => {
				this.messageText = "Не удалось выполнить";
				this.messageColor = "red";
			})
			.finally(() => {
				this.message = true;
				this.loadingBtn = false;
			});
	}
	private createItem() {
		this.loadingBtn = true;
		var arr = this.getCleanFields();
		this.form.widget_fields = arr;
		delete this.form.id;
		this.$store
			.dispatch(WIDGET_CREATE, this.form)
			.then(() => {
				this.tryToInitSelf();
			})
			.then(() => {
				this.messageText = "Виджет " + this.form.widget_name + " добавлен.";
				this.messageColor = "green";
				this.tryToInitSelf();
			})
			.catch(() => {
				this.messageText = "Не удалось выполнить";
				this.messageColor = "red";
			})
			.finally(() => {
				this.message = true;
				this.loadingBtn = false;
			});
	}
	private getCleanFields() {
		var arr = [] as any;
		for (let i = 0; i < this.fields.length; i++) {
			arr.push(
				new WidgetField(
					this.fields[i].template_field_id,
					this.fields[i].field_title,
					this.fields[i].field_value
				)
			);
		}
		return arr;
	}
	private toString(str) {
		return str as string;
	}
	private getColorStyle(str) {
		return "height:100% ;background-color: " + str;
	}
	private isUpdatePage() {
		return this.$route.meta.isUpdate;
	}
	private initStartOptions() {
		this.form.widget_type = 0;
		this.form.widget_position = [,];
		this.form.markers = [];

		//Заполняем препросмотр
		// this.form.widget_fields.forEach((item, key) => {
		//   labels.push(item.field_title);
		//   data.push(+item.field_value);
		// });
		// this.chartType = template.widget_type;

		var item;
		if (localStorage.getItem("widgetToEdit")) {
			console.log("update page");
			try {
				item = JSON.parse(this.toString(localStorage.getItem("widgetToEdit")));
				console.log("item init: ", item);
			} catch (e) {
				localStorage.removeItem("widgetToEdit");
			}
		} else {
			console.log("create page");
		}
		/*
		Object.keys(item).forEach((key) => {
			this.form[key] = item[key];
		});
		*/
		//console.log(this.props.isUpdatePage);
		if (this.isUpdatePage()) {
			this.form.widget_name = item.widget_name;
			this.form.template_id = item.template_id;
			this.form.region_id = item.region_id;
			this.form.indicator_table_id = item.indicator_table_id;
			this.form.widget_position = item.widget_position;
			this.fields = item.widget_fields;

			//this.widgetId = item.id;
			console.log("form: ", this.form);
			this.viewItem();
		}
	}
	private viewItem() {
		if (this.form.region_id) {
			for (let i = 0; i < this.regions.length; i++) {
				if (this.regions[i].id == this.form.region_id) {
					this.region = this.regions[i];
					console.log("region", this.region);
				}
			}
		}
		if (this.form.template_id) {
			//Получаем темплейт
			let template = {} as any;
			for (let i = 0; i < this.templates.length; i++) {
				if (this.form.template_id == this.templates[i].id)
					template = this.templates[i];
			}
			//Поля виджета заполняются полями темплейта
			while (this.fields.length > template.template_fields.length) {
				this.fields.splice(this.fields.length - 1, 1);
			}
			for (let i = 0; i < template.template_fields.length; i++) {
				if (!this.fields[i]) {
					this.fields.push({});
				}
				this.fields[i].template_field_id = template.template_fields[i].id;
				this.fields[i].key_name = template.template_fields[i].key_name;
				this.fields[i].key_type = template.template_fields[i].key_type;
				//this.fields[i].field_title = template.template_fields[i].key_name;
				this.fields[i].key_color = template.template_fields[i].key_color;
			}
			this.form.widget_type = template.widget_type;
		}
		console.log("fields", this.fields);
		let labels = [] as any;
		let colors = [] as any;
		let data = [] as any;
		for (let i = 0; i < this.fields.length; i++) {
			if (this.fields[i].field_title) {
				if (this.fields[i].key_name == "total") {
					this.totalTitle = this.fields[i].field_title;
				} else {
					labels.push(this.fields[i].field_title);
				}
			}

			if (this.fields[i].field_value) {
				if (this.fields[i].key_type == 3) {
					data.push(this.fields[i].field_value);
				} else {
					if (this.fields[i].key_name == "total") {
						this.totalValue = +this.fields[i].field_value;
					} else {
						data.push(+this.fields[i].field_value);
						colors.push(this.fields[i].key_color);
					}
				}
			}
		}

		console.log("type", this.form.widget_type);
		console.log("l", labels);
		console.log("d", data);
		console.log("colors", colors);

		this.previewLabels = labels;
		this.previewColors = colors;
		this.previewData = data;
		this.previewName = this.form.widget_name;
		this.redrawChart();
	}
	private getSrc(address) {
		return process.env.VUE_APP_API_ENDPOINT + "/" + address;
	}
	private forceRedraw() {
		this.test++;
		setTimeout(() => {
			this.test--;
		}, 1);
	}
	private addMarker() {
		this.form.markers.push({
			marker_name: "маркер-" + this.form.markers.length,
			marker_meta: "",
			lon: 0,
			lat: 0,
			image_coords: [0, 0],
		});
		this.currentMarker = this.form.markers.length - 1;
		this.forceRedraw();
	}
	private deleteMarker(num) {
		//Если текущий эл-т существует удаляем
		if (num < this.form.markers.length) {
			this.form.markers.splice(num, 1);
		}
		//Если новый текущий эл-т не сущесвует
		if (num >= this.form.markers.length) {
			//Если элементов не меньше одного
			if (this.form.markers.length - 1 >= 0) {
				num = this.form.markers.length - 1;
			} else num = 0;
		}
		this.forceRedraw();
	}
	private drawMarkers() {
		console.log("draw marks");
		if (typeof this.ctx !== "undefined") {
			console.log("ctx exist");
			let ctx = this.ctx;
			let markers = this.form.markers;
			this.syncMarkersCoords();
			ctx.clearRect(0, 0, 9999, 9999);
			if (this.form.markers.length > 0) {
				console.log("leng", this.form.markers.length);
				for (let i = 0; i < markers.length; i++) {
					ctx.beginPath();
					ctx.fillStyle = "#eb6134";
					console.log(
						"draw marker at",
						markers[i].image_coords[0],
						markers[i].image_coords[1]
					);
					ctx.arc(
						markers[i].image_coords[0],
						markers[i].image_coords[1],
						4,
						0,
						Math.PI * 2,
						true
					);
					ctx.fill();
				}
				if (this.editing) {
					ctx.beginPath();
					ctx.fillStyle = "#eb6134";
					ctx.arc(
						this.currentMarkerCoords.x,
						this.currentMarkerCoords.y,
						4,
						0,
						Math.PI * 2,
						true
					);
					ctx.stroke();
				}
			}
		}
	}
	private latLngToCoords(lng0, lat0, lng1, lat1, lng, lat, w, h) {
		let lngW = lng0 - lng1;
		let latH = lat0 - lat1;
		let lngDotW = lng0 - lng;
		let latDotH = lat0 - lat;
		let lngPercent = lngDotW / lngW;
		let latPercent = latDotH / latH;
		let x = w * lngPercent;
		let y = h * latPercent;
		return { x: x, y: y };
	}
	private coordsToLatLng(lng0, lat0, lng1, lat1, x, y, w, h) {
		let lngW = lng0 - lng1;
		let latH = lat0 - lat1;
		let lngPercent = x / w;
		let latPercent = y / h;
		let lng = lng0 - lngW * lngPercent;
		let lat = lat0 - latH * latPercent;
		return { lng: lng, lat: lat };
	}
	private startEditing() {
		this.editing = true;
	}
	private endEditing() {
		this.editing = false;
	}
	private syncMarkersCoords() {
		for (let i = 0; i < this.form.markers.length; i++) {
			let lng0 = this.region.maps[this.currentMap].lon_0;
			let lat0 = this.region.maps[this.currentMap].lat_0;
			let lng1 = this.region.maps[this.currentMap].lon_1;
			let lat1 = this.region.maps[this.currentMap].lat_1;
			let xy = this.latLngToCoords(
				lng0,
				lat0,
				lng1,
				lat1,
				this.form.markers[i].lon,
				this.form.markers[i].lat,
				800,
				600
			);
			this.form.markers[i].image_coords[0] = xy.x;
			this.form.markers[i].image_coords[1] = xy.y;
		}
	}
	private redrawChart() {
		if (this.chartType !== undefined) {
			var wt = +this.chartType;
		}
		this.chartType = 4;
		setTimeout(() => {
			this.chartType = wt;
			this.$refs.chart.redraw();
			console.log("c type", this.chartType);
		}, 1);
	}
	//Events
	private canvasClick(e) {
		console.log("polygon canvas click");
		if (this.editing) {
			console.log("x", e.offsetX);
			console.log("y", e.offsetY);
			this.editing = false;
			this.form.markers[this.currentMarker].image_coords = [
				e.offsetX,
				e.offsetY,
			];
			let lng0 = this.region.maps[this.currentMap].lon_0;
			let lat0 = this.region.maps[this.currentMap].lat_0;
			let lng1 = this.region.maps[this.currentMap].lon_1;
			let lat1 = this.region.maps[this.currentMap].lat_1;
			console.log("lon lat nw", lng0, lat0);
			console.log("lon lat se", lng1, lat1);
			let lonlat = this.coordsToLatLng(
				lng0,
				lat0,
				lng1,
				lat1,
				e.offsetX,
				e.offsetY,
				800,
				600
			);
			console.log("lon lat ", lonlat);
			this.form.markers[this.currentMarker].lon = lonlat.lng;
			this.form.markers[this.currentMarker].lat = lonlat.lat;
			this.drawMarkers();
		}
	}
	private canvasMouseMove(e) {
		if (this.editing) {
			this.currentMarkerCoords.x = e.offsetX;
			this.currentMarkerCoords.y = e.offsetY;
			this.drawMarkers();
		}
	}
	private canvasMouseLeave(e) {
		this.drawMarkers();
	}
	private scrollHandle(evt) {
		console.log(evt);
	}
	//Hooks
	private created() {
		var item;
		if (localStorage.getItem("widgetToEdit")) {
			item = JSON.parse(this.toString(localStorage.getItem("widgetToEdit")));
			console.log("item created", item.widget_name);

			this.$router.currentRoute.meta.breadcrumb[2].text = "" + item.widget_name;
			console.log("bc", this.$router.currentRoute.meta.breadcrumb);
		}
	}
	private mounted() {
		this.tryToInitSelf();
	}
	private updated() {
		if (this.form.widget_type == 3) {
			if (this.region.maps) {
				if (this.region.maps.length > 0) {
					if (!this.ctx) {
						this.ctx = this.$refs.canvas.getContext("2d");
						console.log("CTX SET");
					}
				}
			}
		}
	}
	private destroyed() {
		localStorage.removeItem("widgetToEdit");
	}
}
