class Widget {
	constructor(
		public id?: number,
		public template_id?: number,
		public widget_name?: string,
		public region_id?: number,
		public indicator_table_id?: number,
		public widget_position?: number[],
		public widget_fields?: WidgetField[],
	) {
		this.template_id = template_id;
		this.widget_name = widget_name;
		this.region_id = region_id;
		this.indicator_table_id = indicator_table_id;
		this.widget_position = widget_position;
		this.widget_fields = widget_fields;
	}
}
class WidgetField {
	constructor(
		public template_field_id: number,
		public field_title: string,
		public field_value: string,
	) {
		this.template_field_id = template_field_id;
		this.field_title = field_title;
		this.field_value = field_value;
	}
}
class WidgetDataTable {
	constructor(
		public table_name: string,
		public values: WidgetDataTableField[],
		public dateCreate?: Date,
		public trash?: boolean,
		public verified?: boolean,
		public id?: number
	) {}
}

class WidgetDataTableCreate {
	constructor(
		public table_name: string,
		public values: WidgetDataTableField[],
		public id?: number
	) {}
}

class WidgetDataTableField {
	constructor(
		public data_value: number,
		public key_name: string,
		public id?: number,
		public data_table_id?: number
	) {}
}

export {
	Widget,
	WidgetDataTable,
	WidgetDataTableField,
	WidgetDataTableCreate,
	WidgetField,
};
